import React from "react";

const AddTeamCategory = () => {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        Add Team Category
      </div>
    </>
  );
}

export default AddTeamCategory