export const paths = {
	dashboard: "/admin/dashboard",
	partners: "/admin/partners",
	team: "/admin/team",
	reach: "/admin/reach",
	talentRequest: "/admin/talent-request",
	successStories: "/admin/success-stories",
	events: "/admin/events",
	chapter: "/admin/chapters",
	programs: "/admin/programs",
	testimonials: "/admin/testimonials",
	enquiries: "/admin/enquiries",
	schoolPrograms: "/admin/academy/school-programs",
	schools: "/admin/academy/schools",
	courses: "/admin/academy/courses",
	settings: "/admin/settings",
	allVolunteers: "/admin/volunteer",
	addVolunteer: "/admin/volunteer/add",
	editVolunteer: "/admin/volunteer/edit/:id",
	listVolunteerCategory: "/admin/volunteer/category",
	addVolunteerCategory: "/admin/volunteer/category/add",
	editVolunteerCategory: "/admin/volunteer/category/edit/:id",
	jobs: "/admin/jobs",
	addNewJob: "/admin/jobs/add",
	editJob: "/admin/jobs/edit",
	viewJob: "/admin/jobs/view",
	companies: "/admin/companies",
	editCompany: "/admin/companies/edit",
	viewCompany: "/admin/companies/view",
	academy: "/admin/academy",
	initiatives: "/admin/initiatives",
	addNewScholarship: "/admin/initiatives/add",
	editScholarship: "/admin/initiatives/edit",
	viewScholarship: "/admin/initiatives/view",
	reports: "/admin/reports",
	sagSchools: "/admin/stem-a-girl/schools",
	sagCourses: "/admin/stem-a-girl/courses",
	sagActivities: "/admin/stem-a-girl/activities",
	sagEvents: "/admin/stem-a-girl/events",
	sagImpactStories: "/admin/stem-a-girl/impact-stories",
	sagTestimonials: "/admin/stem-a-girl/testimonials",
};
